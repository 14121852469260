html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none !important;
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  overflow: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #21caf1 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #21caf1 !important;
}

.MuiSelect-select.MuiSelect-select {
  color: rgba(0, 0, 0, 0.54);
}

.text-center {
  text-align: center;
}

.position-relative {
  position: relative;
}

.text-white {
  color: white;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.makeStyles-table-75 th {
  font-size: 15px;
  font-weight: bold;
}

.MuiTableCell-root {
  padding: 5px !important;
}

.medications-box {
  border: 2px solid #059dfd;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0px;
  width: 50%;
}

.medications-box h4 {
  margin-bottom: 0px;
  margin-top: 10px;
}

/* .MuiTabs-flexContainer {
  overflow: auto;
} */
/* .MuiSvgIcon-root{
  fill: #000 !important;
} */
.ui-card {
  width: 32%;
  box-shadow: 0 7px 15px rgba(0, 9, 128, 0.05),
    0 12px 28px rgba(0, 9, 128, 0.075);
  margin-bottom: 30px;
}

.card-header.bg-dark-gray {
  color: rgba(255, 255, 255, 1);
  background-color: black;
}

.card-header.bg-sky {
  color: rgba(255, 255, 255, 1);
  background-color: #5fa6cf;
}

.card-header {
  position: relative;
  background: #fff;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

.card-header {
  padding: 2rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-header::after {
  display: block;
  content: '';
  height: 100%;
  background: url(../src/images/hero-curve-layer.svg) bottom no-repeat;
  background-size: auto;
  background-size: contain;
  position: absolute;
  bottom: -2px;
  left: -6px;
  right: -6px;
  z-index: 1;
}

.Topheader {
  width: 1200px;
  margin: 0px auto;
  padding: 15px 0;
}

ul.Topnav {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}

.price .period {
  font-size: 1.1rem;
  font-weight: 600;
}

.price .price {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  margin: 0 5px;
}

.price .period {
  font-size: 1.1rem;
  font-weight: 600;
}

.card-body {
  background-color: #f6f7f8;
}

.ui-card ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.pricing-col .pricing-heding {
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
}

.card-body {
  padding: 2rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  min-height: 300px;
}

.card-body ul li {
  padding: 3px;
  font-weight: normal;
}

.owl-stage .ui-card:nth-child(2) {
  margin-top: -1rem;
  position: relative;
  z-index: 999999;
}

.owl-stage .ui-card:nth-child(1) {
  margin-right: -3rem;
  margin-left: 3rem;
}

.owl-stage .ui-card:nth-child(3) {
  margin-right: 3rem;
  margin-left: -3rem;
}

.pricing-col h4 {
  text-align: center;
  margin-top: 40px;
}

.ui-card {
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: center;
}

.ui-card h4 {
  font-size: 24px;
  color: #fff;
  margin: 15px 0;
}

.ui-card h6 {
  font-size: 18px;
  color: #fff;
  margin: 15px 0;
}

.shadow-xl {
  box-shadow: 0 7px 15px rgba(0, 9, 128, 0.05),
    0 12px 28px rgba(0, 9, 128, 0.075);
}

.owl-stage > div:nth-child(2) .ui-card .card-header {
  padding-top: 3rem;
}

.card-header.ui-gradient-peach {
  color: rgba(255, 255, 255, 0.85);
  background: -moz-linear-gradient(45deg, #fe60a1 0%, #ff8765 100%);
  background: linear-gradient(45deg, #fe60a1 0%, #ff8765 100%);
  position: relative;
  z-index: 9999;
}

.color-card .card-body {
  background-color: #fff;
}

.st-btn {
  background-color: #3f4b79;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.owl-stage {
  display: flex;
  justify-content: center;
}

.st-pricing-feature h2 {
  width: 88%;
}

.feature2 {
  padding-right: 55px;
}

.feature3 {
  padding-left: 55px;
}

.fc .fc-highlight {
  background: #3f4b79 !important;
}

#calendar-section .fc-prev-button,
#calendar-section .fc-next-button {
  color: #3f4b79 !important;
}

.MuiToggleButton-root {
  background: #ccc;
}

#available-times-div {
  height: 400px !important;
  overflow-y: auto;
}

.time-btn {
  color: #fff !important;
  border: none !important;
  background: #3f4b79 !important;
}

.confirm-btn {
  background-color: #091c2e !important;
}

.time-btn:hover {
  border: none;
}

ul.Topnav {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul.Topnav li {
  margin-left: 15px;
}

ul.Topnav li a {
  color: #000;
}

ul.Topnav li a:hover {
  text-decoration: none;
}

.AboutContent {
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
}

.OurDoctor {
  width: 100%;
  padding: 0 0 70px;
}

.OurDoctor h3 {
  font-size: 28px;
  text-align: center;
  color: #263159;
  font-weight: normal;
  margin-bottom: 40px;
}

.service p {
  font-size: 16px;
  line-height: 24px;
}

.location-icon a {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #263159;
  justify-content: center;
}

.location-icon img {
  margin-right: 10px;
}

.doctorcontent {
  width: 100%;
  padding: 0 15px;
  text-align: center;
}

.doctorcontent h2 {
  font-size: 20px;
  color: #2f57a8;
  margin-bottom: 10px;
}

.doctorcontent p {
  font-size: 14px;
  color: #a4a4a4;
  margin-top: 0px;
}

.bodycontent {
  width: 1200px;
  margin: 0px auto;
}

.AboutContent {
  width: 100%;
  padding: 40px 0 90px;
}

ul.serviceList {
  list-style: none;
  margin: 0 0 80px;
  padding: 0px;
  text-align: center;
}

ul.serviceList li {
  padding-left: 20px;
  color: #263159;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  width: 20%;
  padding: 20px;
  position: relative;
  text-align: center;
  box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin: 15px 15px;
}

.doctorbox {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
}

.AboutContent h2 {
  font-size: 28px;
  color: #263159;
  font-weight: normal;
}

.doctorimg {
  width: 100%;
}

.AboutContent p {
  font-size: 18px;
  color: #263159;
  line-height: 24px;
}

/* button {
    background: #3f4b79 !important;
    color: #fff !important;
  } */

.service {
  width: 100%;
  padding-bottom: 50px;
}

.service h2 {
  font-size: 28px;
  color: #263159;
  text-align: center;
  font-weight: normal;
  margin-bottom: 30px;
}

.contact-info {
  width: 50%;
  margin: 0px auto;
}

.contact-info ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.contact-info ul li h5 {
  margin: 0px;
  font-size: 18px;
  color: #263159;
}

.contact-info ul li p {
  margin-top: 10px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
}

.contact-info ul li:nth-child(1) {
  width: 42%;
}

.contact-info ul li:nth-child(2) {
  width: 42%;
}

.contact-info ul li:nth-child(3) {
  width: 100%;
}

/* blog section */

.blog {
  width: 90%;
  padding: 90px 0;
  margin: 0px auto;
}

.blog h4 {
  text-align: center;
}

.box-shadow,
.featured-imagebox-blog {
  -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  -moz-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
}

.box-shadow2 {
  -webkit-box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
  -moz-box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
  box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
}

.featured-imagebox-blog {
  background-color: #fff;
  margin-bottom: 10px;
}

.featured-imagebox-blog .featured-thumbnail {
  position: relative;
  overflow: hidden;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.featured-imagebox-blog .featured-content {
  padding: 45px 25px 25px 25px;
  position: relative;
}

.featured-imagebox-blog .ttm-box-post-date {
  left: 25px;
  top: -35px;
  border-radius: unset;
}

.ttm-box-post-date {
  position: absolute;
  left: 15px;
  top: 15px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
  background: #2d4a8a;
}

.ttm-box-post-date .entry-date {
  height: 60px;
  width: 60px;
  display: block;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.ttm-entry-date span {
  display: block;
}

.featured-title h5 {
  font-size: 19px;
  line-height: 26px;
  margin-bottom: 10px;
  margin-top: 0px;
  color: rgb(49, 61, 106);
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow {
  width: 43px !important;
  height: 43px !important;
  border-radius: 50% !important;
  background: rgb(49, 61, 106, 0.7) !important;
}

.blog h6 {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.84);
  font-weight: normal;
  font-size: 18px;
  text-align: center;
}

.featured-imagebox-blog .post-meta {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
}

.featured-imagebox-blog .post-meta .ttm-meta-line:not(:last-child) {
  padding-right: 23px;
}

.post-meta .ttm-meta-line {
  position: relative;
  color: rgb(49, 61, 106, 0.7) !important;
}

.featured-desc p {
  margin-bottom: 0;
  font-size: 16px;
  margin-bottom: 10px;
}

.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-right {
  padding-right: 35px;
}

@media all and (min-width: 768px) and (max-width: 1199px) {
  .owl-stage .ui-card:nth-child(1) {
    margin-right: -2rem;
    margin-left: 2rem;
  }
  .ui-card {
    width: 33%;
  }
  .Topheader {
    width: 95%;
    padding: 0 15px;
  }
  .bodycontent {
    width: 95%;
    padding: 0 15px;
  }
  .owl-stage .ui-card:nth-child(3) {
    margin-right: 2rem;
    margin-left: -2rem;
  }
  .ui-card h4 {
    font-size: 20px;
  }
  .price .price {
    font-size: 20px;
  }
  .feature2 {
    padding-right: 35px;
  }
  .feature3 {
    padding-left: 35px;
  }
  .left-box1 {
    padding-left: 10px;
  }
  .right-box3 {
    padding-right: 10px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  /* .MuiTabs-flexContainer {
    overflow-x: auto !important;
  } */
  .Topheader {
    width: 95%;
    padding: 15px;
  }
  .bodycontent {
    width: 95%;
    padding: 0 15px;
  }
  .MuiTab-root {
    min-width: 120px !important;
  }
  .owl-stage .ui-card:nth-child(1) {
    margin-right: -1.5rem;
    margin-left: 1.5rem;
  }
  .owl-stage {
    flex-direction: column;
  }
  .ui-card {
    width: 89%;
  }
  .owl-stage .ui-card:nth-child(2) {
    margin-top: 0px;
    margin-left: 1.5rem;
  }
  .owl-stage .ui-card:nth-child(3) {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}

/*test*/
/* scheduler */

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  color: #3f4b79 !important;
  font-family: 'Poppins' !important;
}

.fc .fc-highlight {
  background: #99cbff !important;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #464646;
}

#calendar-section .fc-toolbar-title {
  font-size: 1rem;
  font-weight: 500;
  background-color: #efefef;
  padding: 10px 20px;
  color: #3f4b79;
}
